import React from "react"
//import { LanguageContext } from "../context"
import { Carousel } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { useStaticQuery, graphql, navigate, Link }  from  "gatsby"
import Button from "./button-visit-us"
import ButtonGeneric from "./button-generic"
import Utils from '../utils'

 const Slider = () => {
   //const { t } = useContext(LanguageContext)
   /* Add filter on backend */
   const data = useStaticQuery(graphql`
      query SliderQuery {
        slides: allNodeSliderTramuntana {
          edges {
            node {
              id
              title
              field_cta {
                title
                uri
                url
              }
              field_image {
                title
              }
              field_subtitle
              relationships {
                field_image {
                  image_style_uri {
                    large
                    medium
                    thumbnail
                    wide
                    _1440x408
                  }
                  publicUrl
                }
              }
            }
          }
        }
      }
    `)

    const slides = data?.slides?.edges

    const onClick = (uri) => {
      if(!uri){
        return;
      }
      if(uri.includes('internal')){
        navigate(uri)
      }else{
         window.location = uri
      }
    }

   return (
     <section>
         <div className="container-fluid p-0" >
             <div className="row">
                 <div className="col-12">
                     <Carousel className="m-h-800">
                      {slides && slides.length && slides.map((slide) => {
                        return(
                          <Carousel.Item key={slide?.node?.id} className="drk">
                              <img
                                  className="d-block w-100"
                                  src={`${slide?.node?.relationships?.field_image.publicUrl}?img=${slide?.node?.id}`}
                                  alt={slide?.node?.title}
                              />
                              <Carousel.Caption>
                                  <h1 className="slide-title small-h1 m-b-48">{slide?.node?.title}</h1>
                                  <p className="slide-description m-b-64 max-750 small-m-b-20">{slide?.node?.field_subtitle}</p>
                                  {
                                    slide?.node?.field_cta?.uri
                                    && slide?.node?.field_cta?.uri.includes("internal")
                                    && slide?.node?.field_cta?.uri === "internal:#visitans"
                                    ?
                                    <>
                                    <Button
                                       className="btn btn-default btn-outline-secondary white"
                                       title={slide?.node?.field_cta?.title}
                                     />
                                   </>
                                   :
                                   <>
                                   <Link
                                      className="btn btn-default btn-outline-secondary white"
                                      to={slide?.node?.field_cta?.url?.replace("/ca/", "/")}
                                    >
                                      {slide?.node?.field_cta?.title}
                                      </Link>
                                    </>
                                  }
                              </Carousel.Caption>
                          </Carousel.Item>
                        )

                      })
                      }

                     </Carousel>
                 </div>
             </div>
         </div>
     </section>
   )
 }

 export default Slider
