import React, {useState, useEffect} from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Slider from "../components/slider"
import Ribbon from "../components/ribbon"
import HomeTopContent from "../components/home-top-content"
import ProjectsList from "../components/projects/projects-list"
import EventsList from "../components/events/events-list"
import BlogList from "../components/blog/blog-list"
import TeamList from "../components/team/team-list"
import SponsorsList from "../components/sponsors"
import moment from "moment"
import Utils from "../utils"

const check = moment();
//const day = check.format('dddd') // => ('Monday' , 'Tuesday' ----)
const month = check.format('MMMM') // => ('January','February.....)
//const year = check.format('YYYY') // => ('2012','2013' ...)

const HomeTemplate = ({ data, location, pageContext }) => {
  const [isTransparent, setIsTransparent] = useState(true)

  const allNodeBlogPostTramuntana = data?.allNodeBlogPostTramuntana?.nodes
  const allNodeTeam = data?.allNodeTeam?.nodes
  //const allNodePodcast = data.podcasts
  const preachSeries = data?.preachSeries?.edges
  const slides = data?.slides

  const homeTop = data?.topContent
  const sponsors = data?.sponsors?.edges
  const replacedString = homeTop?.edges[0]?.node?.body?.value?.replace('Famílies', '<span class="fill-backgrond">Famílies</span>');
  const shuffledTeam = Utils.shuffleObjectsArray(allNodeTeam);
  const shuffledSponsors = sponsors ? Utils.shuffleObjectsArray(sponsors) : [];

  
  useEffect(() => {
    const handleScroll = () => {
      const sliderHeight = document.querySelector('.slider-container')?.offsetHeight;
      const currentScroll = window.scrollY;
      if (currentScroll > sliderHeight) {
        setIsTransparent(false);
      } else {
        setIsTransparent(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <Layout
    location={location}
    title={translate(pageContext?.langcode, 'Home')}
    langcode={pageContext?.langcode}
    translationPaths={homePagePaths}
    className="page-home"
    >
    <Seo
      title={translate(pageContext?.langcode, 'Home')}
      lang={pageContext?.langcode}
      image={slides?.edges[0]?.node?.relationships?.field_image?.image_style_uri?._653_432}
      description={slides?.edges[0]?.node?.field_subtitle}
    />
    <Header
      lang={pageContext?.langcode}
      isTransparent={isTransparent}
     />
     <div className="slider-container">
      <Slider
        lang={pageContext?.langcode}
        slides={slides}
       />
      </div>
      <HomeTopContent
        lang={pageContext?.langcode}
        text={/*<span>Necessites <span className="fill-backgrond">pregària</span>?
              No importa al que t'enfrontis, ens encantaria pregar amb tu!</span>
         */
         <span dangerouslySetInnerHTML={{__html: replacedString}} />
        }
         className="row home-top-content m-b-152 p-t-110 small-p-t-b-40"
         buttonTitle={homeTop?.edges[0]?.node.field_cta.title}
         buttonUrl={homeTop?.edges[0]?.node?.field_cta?.url.replace("/ca/", "/")}
       />
       <ProjectsList
        lang={pageContext?.langcode}
        projects={data?.projects}
        key={Math.random()}
       />
       <EventsList
        title={"Events"}
        month={month}
        lang={pageContext?.langcode}
        data={data?.events?.edges}
        className="page-home-events"
        hidemonth={true}
       />
       <BlogList
         title="Publicacions recents"
         blogPosts={allNodeBlogPostTramuntana}
         lang={pageContext?.langcode}
         buttonTitle="Anar al Blog"
         buttonUrl="/blog"
        />
        <TeamList
         title="El nostre equip"
         teamList={shuffledTeam}
         lang={pageContext?.langcode}
         className="page-home-team"
         />
         <SponsorsList
          title="Els nostres col·laboradors"
          sponsorsList={sponsors}
          lang={pageContext?.langcode}
          className="page-home-sponsors p-b-80"
          />
       <Footer lang={pageContext?.langcode} />
    </Layout>
  )
}

export default HomeTemplate

export const pageQuery = graphql`
  query PageById($langcode: String!) {
    slides: allNodeSliderTramuntana(
      filter: {langcode: {eq: $langcode}, status: {eq: true}}
    )
    {
      edges {
        node {
          id
          title
          field_cta {
            title
            uri
            url
          }
          field_image {
            title
          }
          field_subtitle
          relationships {
            field_image {
              image_style_uri {
                large
                medium
                thumbnail
                wide
                _1440x408
                _1103x488
                _1440x960
                _653_432
              }
            }
          }
        }
      }
    }
    projects: allNodeProjectsTramuntana(
      filter: {langcode: {eq: $langcode}, status: {eq: true}, field_display_pages:
        {elemMatch:
          {drupal_internal__target_id:
            {eq: 3438}
          }
        }}
    ) {
   edges {
     node {
       id
       drupal_id
       field_direction
       field_email
       field_phone
       field_social_media {
         first
         second
       }
       field_schedule
       relationships {
         field_image {
           image_style_uri {
             _653_432
             thumbnail
             medium
             large
             wide
           }
         }
       }
       body {
         summary
         value
       }
     }
   }
 }
 topContent: allNodeTopContentTramuntana(
   limit: 1
   sort: {fields: created, order: DESC}
   filter: {field_main_content: {drupal_internal__target_id: {eq: 3438}}}
 ) {
   edges {
     node {
       title
       body {
         summary
         value
       }
       field_cta {
         title
         uri
         url
       }
     }
   }
 }
 events: allNodeEventTramuntana(
   sort: {fields: field_start_time, order: ASC}
   limit: 3
  ) {
  edges {
    node {
      body {
        summary
      }
      id
      drupal_id
      field_start_time
      created
      field_end_time
      field_subtitle
      langcode
      title
      path {
        langcode
        alias
      }
      relationships {
        field_image {
          drupal_id
          image_style_uri {
            large
            medium
            thumbnail
            wide
          }
        }
        field_project {
          title
          path {
            alias
          }
          langcode
          drupal_id
        }
      }
      currentMonth
      field_location
    }
  }
}
allNodeBlogPostTramuntana(
    limit: 3
    sort: {fields: created, order: DESC}
    filter: { langcode: { eq: $langcode } }
  ) {
  nodes {
    langcode
    title
    created(formatString: "DD MMMM YYYY", locale: $langcode)
    body {
      summary
    }
    path {
      alias
    }
    relationships {
      field_image {
        image_style_uri {
          large
          medium
          thumbnail
          wide
          _294x192
          _541x359
          _653_432
        }
      }
    }
  }
}
allNodeTeam:allNodeTeamTramuntana(limit: 20, filter: { langcode: { eq: $langcode } }) {
    nodes {
      langcode
      title
      field_email
      field_role
      field_team_type
      relationships {
        field_image {
          image_style_uri {
            large
            medium
            thumbnail
            wide
            _294x192
            _541x359
            _653_432
          }
        }
      }
    }
  }
  sponsors: allNodeSponsorsTramuntana(sort: {fields: created, order: ASC}) {
    edges {
      node {
        title
        field_url {
          title
          uri
        }
        relationships {
          field_image {
            image_style_uri {
              _653_432
              thumbnail
              sponsors
            }
          }
        }
      }
    }
  }
 home: allNodeHomepageTramuntana {
   edges {
     node {
       drupal_internal__nid
     }
   }
 }
}
`
